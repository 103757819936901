.container {
    min-height: 90vh;
    background-color: var(--DARK_GREY_COLOR);
    color: #EFEAEA;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
}

.container p {
    color: var(--WHITE_COLOR);
}
.main {
    
    display: flex;
    justify-content: space-around;
    /* flex-wrap: wrap; */
    padding: 100px 0px 30px 0px;
    
}
.block {
    border-left: 1px solid #979797;
    width: 300px;
    padding: 20px;
}

.end {
    margin: auto;
    text-align: center;
    width: 100vw;
}
.link {
    display: block;
    text-decoration: none;
    color: white;
    font-size: 1.4rem;
}
.link:hover {
    color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 741px) {
    .main {
        flex-direction: column;
        align-items: center;
    }
    .block {
        border-top: 1px solid #979797;
        border-left: none;
    }
}