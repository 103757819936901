.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.container h4 {
    width: 100vw;
    text-align: center;
}

.cards {
    max-width: 800px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}