.icon {
    margin: 10px;
    width: 30px;
    cursor: pointer;
    transition: 0.3s
}

.icon:hover {
    transform: scale(1.05)
}
   