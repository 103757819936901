.button {
    width: 190px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--PRIMARY_COLOR);
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.3);
    margin: auto;
    transition: 0.3s;
}
.button:hover {
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.5);
    transform: scale(1.03)
}
.p {
    margin: auto;
    color: white;
    font-size: 1rem;
    text-decoration: none;
}

