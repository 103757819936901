/* import {STANDARD_FONT, TEXT_COLOR} from ../../Config/style */

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

.container {
    position: absolute;
    background-color: rgba(0,0,0,0.8);
    margin-top: 0;
    animation: fadeIn 0.2s ease-in;
}

.exit {
    animation: fadeOut 0.1s ease-in;
    opacity: 0;
}


.menuUl {
    list-style-type: none;
    text-align: center
}

.menuLi {
    margin: 10px;
}

.link {
    text-decoration: none;
    font-family: var(--STANDARD_FONT);
    color: white;
    font-size: 30px;
}

.icon {
    margin: 10px;
    width: 30px;
    cursor: pointer;
}
    
