.card {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 10px;
    max-width: 400px;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3); 
}

.p {
    margin-top: 10px;
    text-align: center;
}

.inputText {
    border-radius: 10px;
    margin: 20px 0px 20px 0px;
    height: 30px;
    width: 250px;
    border: 1px solid lightgrey;
    padding: 5px;
}

@media only screen and (max-width: 450px) {

}