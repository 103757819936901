.container {
    min-height: var(--FULL_SCREEN);
}

.textContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    flex-grow: 1;
    max-width: 500px;
    font-size: 1.2rem;
}

.textContainerMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    flex-grow: 1;
    max-width: 500px;
    font-size: 1.2rem;
}

.contactFirst {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px;
    margin-top: 50px;
}

.maps {
    min-height: 400px;
    margin-bottom: 100px;
}

.contactForm {
    width: 100%;
    min-height: 500px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around; 
    /* background-color: #EDEAE0; */

}


.p {
    margin-top: 10px;
}

.inputText {
    border-radius: 10px;
    margin: 20px;
    height: 30px;
    width: 250px;
    border: 1px solid lightgrey;
    padding: 5px;
}

.image {
    max-height: 600px;
}

@media only screen and (max-width: 450px) {
    .maps {
        margin-bottom: 0px;
    }
    
}
