.container {
    min-height: var(--FULL_SCREEN);
}

.space {
    height: 80px;
}

.blogCards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}