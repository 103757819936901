/* import Wallpaper from ../../AppAssets/Images/beatles-music-band-group.jpg */

.fullscreen {
    /* background-image: url("../../AppAssets/Images/wopii1-low.jpg");
    background-position: right bottom;
    background-size: 40vw;
    background-repeat: no-repeat; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-end;
    overflow: hidden;
}

.startImage {
    min-height: 300px;
    height: 45vw;
    max-height: 700px;
    max-width: 700px;
}

.logo {
    height: 100px;
    margin: 0 80px 120px 80px;
}

.decContainer {
    overflow: hidden;
    width: 100vw;
    position: absolute;
}

.sale {
    font-family: var(--STANDARD_FONT);
    font-weight: 100;
    margin: 100px;
    width: 100vw;
    min-width: 200px;
    /* padding: 100px; */
}

.topDecoration {
    height: 100vh;
    min-height: 1000px;
    width: 300vw;
    min-width: 2000px;
    position: relative;
    top: -80vh;
    left: -50vw;
    background-color: var(--FADE_ORANGE_COLOR);
    transform: rotate(-15deg);
}

.topContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* height: 250px; */
    align-items: center;
    padding: 120px 50px 0px 50px;
    margin-bottom: 50px;
}

.aboutText {
    margin: 0px 50px 0px 50px;
    max-width: 600px;
    font-size: 1rem;
}

.deviceImage {
    margin: 50px 50px 50px 50px;
    max-height: 200px;
}

.galleryContainer {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.imageContainer {
    width: 25%;
    min-width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    margin-top: 35px;
}

.smallImages {
    height: auto;
    width: auto;
    max-height: 80px;
    max-width: 180px;
}

.contactContainer {
    padding-top: 60px;
    max-width: 1000px;
    min-height: 300px;
}

.image {
    height: 45vw;
    min-height: 300px;
}

.descriptionContainer {
    padding: 150px 10px 100px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.contactCard {
    padding: 100px 0px 100px 0px;
    display: flex;
    justify-content: center;
}

.contactText {
    margin-bottom: 20px;
    padding: 10px;
}

@keyframes fade {
    0% {opacity: 0.3;}
    50% {opacity: 1;}
    100% {opacity: 0.3;}
}

.logoLoading {
    animation-name: fade;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@media only screen and (max-width: 450px) {
    .topDecoration {
        top: -120vh;
    }
    .sale {
        padding: 50px;
        margin: auto;
    }
    .descriptionContainer {
        padding-top: 30px;
    }
}
