.container {
    margin: 0px 0 0px 0;
    min-height: 200px;
}

.overflow {
    overflow: hidden;
}

.bottom {
    height: 210px;
    width: 200vw;
    position: relative;
    top: -200px;
    left: -50px;
    z-index: -1;
    transform: rotate(-5deg);
}
.top {
    height: 210px;
    width: 200vw;
    position: relative;
    top: 200px;
    left: -50px;
    z-index: -1;
    transform: rotate(5deg);
}

.green {
    background-color: var(--GREEN_COLOR);
}
.red {
    background-color: var(--RED_COLOR);
}
.blue {
    background-color: var(--BLUE_COLOR);
}
.beige {
    background-color: var(--BEIGE_COLOR);
}

.edgeTop {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 65vw 70px 35vw;
    border-color: transparent transparent var(--RED_COLOR) transparent;
}

.greenEdge {
    border-color: transparent transparent var(--GREEN_COLOR) transparent;
}
.redEdge {
    border-color: transparent transparent var(--RED_COLOR) transparent;
}
.blueEdge {
    border-color: transparent transparent var(--BLUE_COLOR) transparent;
}
.beigeEdge {
    border-color: transparent transparent var(--BEIGE_COLOR) transparent;
}

.splitTop {
    background-image: url('../../AppAssets/wallpaper.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 45% 0%;
    /* height: 90vh; */
    padding-top: 150px;
    padding-bottom: 150px;
    max-height: 400px;
}
/* .splitTopFill {
    width: 100vw;
    height: 100px;
    position: relative;
    top: -100px;
    background-color: white;
} */

@media only screen and (max-width: 450px) {
    .container {
        min-height: 450px;
    }
    .splitTop {
        max-height: unset;
    }
}