.container {
    width: 40vw;
    min-width: 300px;
    min-height: 300px;
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.3);
    padding-bottom: 10px;
    transition: 0.3s;
    margin: 20px;
}

.containerMini {
    width: 200px;
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.3);
    padding-bottom: 10px;
    transition: 0.3s;
    margin: 20px;
}

.container:hover {
    transform: scale(1.01);
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.5);
    cursor: pointer;
    border-radius: 5px;
}
.containerMini:hover {
    transform: scale(1.01);
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.5);
    cursor: pointer;
    border-radius: 5px;
}

.img {
    width: 40vw;
    min-width: 300px;
}

.imgMini {
    width: 200px;
}

h1 {
    margin:5px;
    text-align: center;
    font-weight: 300;
}

.h1Mini {
    font-size: 1rem;
}

.link {
    text-decoration: none;
    color: var(--TEXT_COLOR);
}