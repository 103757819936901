.container {
    min-height: var(--FULL_SCREEN);
}

.topContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* height: 250px; */
    align-items: center;
    padding-top: 150px;
}

.aboutText {
    margin: 20px 50px 0px 50px;
    max-width: 500px;
    font-size: 1rem;
}

.deviceImage {
    margin: 0 50px 0 50px;
    max-height: 170px;
}

.contactContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    /* height: 400px; */
    padding-bottom: 50px;
}

.contactText {
    width: 100vw;
    margin: 0 0 40px 0 ;
    font-size: 1.2rem;
}

.profileContainer {
    
}

.profile {
    /* height: 800px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.profileImage {
    height: 800px;
    margin: 0 50px 0 0;
}

.profilePresentation {
    height: 100%;
    margin: 0 0 0 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.h2 {
    font-size: 2rem;
    font-weight: 100;
    margin: 0 0 50px 0;
}

.profileText {
    margin: 0 0 100px 0;
    font-size: 1.3rem;
    font-weight: 100;
}

@media only screen and (max-width: 450px) {
    .profilePresentation {
        margin: 0 20px 0 20px;
    }
}
