.container {
    /* position: fixed;
    left: 10px;
    top: 50px; */
}

.buttonContainer {
    margin: 5px;
    transition: 0.3s;
    opacity: 0.8;
}

.buttonContainer:hover {
    transform: scale(1.1);
    cursor: pointer;
    opacity: 1;
}

@media only screen and (max-width: 870px) {
    .container {
        display: inherit;
    }
}