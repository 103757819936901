.container {
    height: 50px;
    width: 100vw;
    position: fixed;
    z-index: 1;
}

.logo {
    height: 25px;
    
}

.center {
    margin: auto;
    flex: 1;
    text-align: center;
}

.transparent {
    background-image: transparent;
}

.gradient {
    background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0));
}

.header {
    height: 50px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    
}
.icon {
    height: 25px;
    margin: 10px;
    transition: 0.3s;
}
.icon:hover {
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.3);
    transform: scale(1.05);
    cursor: pointer;
}
