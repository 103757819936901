.container .img {
    height: 2px;
}

.galleryContainer {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.imageContainer {
    width: 25%;
    min-width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    margin-top: 35px;
}

.smallImages {
    height: auto;
    width: auto;
    max-height: 80px;
    max-width: 180px;
}

/* @media only screen and (max-width: 650px) {
    .imageContainer {
        min-width: 100px;
    }
} */

/* @media only screen and (max-width: 450px) {
    .imageContainer {
        min-width: 100px;
    }
} */