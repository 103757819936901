.container {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    flex-wrap: wrap;
}

.image {
    text-align: center;
}

.blogPost {
    max-width: 800px;
    padding: 10px;
}

.blogpost h1 {
    
}

.blogPost img {
    max-width:  700px;
    width: 90%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
}

.blogPost h2 {
    /* text-align: center; */
    color: var(--TEXT_COLOR);
    font-size: 1.5rem;
    padding: 0 4px 0 4px;   
}

.blogPost p{
    font-size: 1.2rem;
    font-weight: lighter;
    line-height: 1.9rem;
    padding: 0 4px 0 4px;   
}

.blogPost li {
    font-size: 1.4rem;
    font-weight: lighter;
    margin: 10px;
}

.blogPost h3 {
    font-size: 1.6rem;
    color: var(--DARK_ORANGE);
}

.blogPost h4 {
    font-size: 1.5rem;
}

.date {
    font-size: 0.9rem;
}